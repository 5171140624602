// Vue is currently not used in any component
// import Vue from 'vue';
// import StHelloVue from './components/hello-vue/HelloVue.vue';

// new Vue({
// 	el: '.vue-app',
// 	components: {
// 		StHelloVue
// 	},
// });

import './js/vendor/polyfill/polyfill-nodelist-foreach.js';
import './js/vendor/polyfill/polyfill-custom-event.js';

import mainNav from './components/navigation/main-navigation/components.main-navigation.js';
import iframePopup from './components/iframe-popup/components.iframe-popup.js';
import accordion from './components/accordion/components.accordion.js';
import CustomSelect from './components/form-elements/custom-select/custom-select.js';
import MaxSelect from './components/form-elements/custom-select/max-select.js';
import bufferBox from './components/buffer-box/components.buffer-box.js';
import truncate from './components/truncate/components.truncate.js';
import './components/slideshow/compontents.glide.js';
import './components/weather-date/components.weather-date.js';
import './components/checkout/checkout-receipt/components.checkout-receipt.js';

/* Utilities */
import openInDialog from './js/utilities/open-in-dialog/u-open-in-dialog.js';

// init Main navigation
mainNav();

// init iframePopup
iframePopup();

const customSelect = new CustomSelect;
customSelect.handleFieldset();

const maxSelect = new MaxSelect;
maxSelect.handleCheckboxes();

// Init accordions
accordion(document.querySelectorAll('[data-module="accordion"]'));

// Init bufferBox
bufferBox();

// Init truncate
truncate();

// Init openInDialog
openInDialog('.open-in-dialog', false);
