import ajax from '../../../js/utilities/ajax/u-ajax.js';

export default () => {
  const mainNav = document.querySelector('[data-module="main-nav"]');

	if(!mainNav) { return; }

  const filler = document.createElement('div');
  filler.classList.add('c-main-navigation__filler');

  mainNav.appendChild(filler);

  function showContainer(navToggleBtn) {
		if (navToggleBtn.parentNode.parentNode.querySelector('.c-shortcuts-menu')) {
			const previousMenuButtonWidth = navToggleBtn.parentNode.parentNode.previousElementSibling.clientWidth;
			const maxWidth = window.innerWidth >= 1500 ? 1365-previousMenuButtonWidth : 1320-previousMenuButtonWidth;
			const margin = window.innerWidth >= 1150 ? 80 : 60;
			navToggleBtn.parentNode.nextElementSibling.style.cssText = `max-width: ${maxWidth}px; width: ${window.innerWidth-previousMenuButtonWidth-margin}px`;
		}
		navToggleBtn.parentNode.classList.add('c-main-navigation__trigger--expanded');
    navToggleBtn.setAttribute('aria-expanded', 'true');
    navToggleBtn.parentNode.nextElementSibling.classList.add('c-main-navigation__container--show');
    filler.style.cssText = `display: block; height: ${navToggleBtn.parentNode.nextElementSibling.clientHeight + 10}px;`;
		document.querySelector('.c-main-navigation').classList.add('c-main-navigation--active');

    window.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				hideContainer(navToggleBtn);
			}
		});

		mainNav.addEventListener('mouseleave', (event) => {
			hideContainer(navToggleBtn);
		});
	}

	function hideContainer(navToggleBtn) {
    filler.style.cssText = `display: none; height: 0;`;
		navToggleBtn.parentNode.classList.remove('c-main-navigation__trigger--expanded');
    navToggleBtn.parentNode.nextElementSibling.classList.remove('c-main-navigation__container--show');
    navToggleBtn.setAttribute('aria-expanded', 'false');
		document.querySelector('.c-main-navigation').classList.remove('c-main-navigation--active');
		navToggleBtn.blur()

		window.removeEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				hideContainer(navToggleBtn);
			}
		});

		mainNav.removeEventListener('mouseleave', (event) => {
			hideContainer(navToggleBtn);
		});
	}

	const navToggleButtons = mainNav.querySelectorAll('.c-main-navigation__button');
  navToggleButtons.forEach(navToggleBtn => {
    const navWrapper = navToggleBtn.parentNode.parentNode.querySelector('.c-main-navigation__container');
		
    navToggleBtn.setAttribute('aria-controls', navWrapper.getAttribute('id'));
    navToggleBtn.setAttribute('aria-expanded', 'false');
		navToggleBtn.parentNode.classList.remove('c-main-navigation__trigger--expanded');

		navToggleBtn.addEventListener('click',function () {
			if (!this.parentNode.classList.contains('c-main-navigation__trigger--expanded')) {
				/* Reset siblings */
				navToggleButtons.forEach(button => {
					hideContainer(button);
				});
				showContainer(this);
			}
			else {
				hideContainer(this);
			}
		});
  });

	const navTrigger = mainNav.querySelectorAll('.c-main-navigation__trigger');
	navTrigger.forEach(trigger => {
		trigger.addEventListener('mouseenter',function () {
			const button = this.querySelector('.c-main-navigation__button');
			if (!button.parentNode.classList.contains('c-main-navigation__trigger--expanded')) {
				/* Reset siblings */
				navToggleButtons.forEach(button => {
					hideContainer(button);
				});

				showContainer(button);
			}
		});
  });

	var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  //if(width > 1024) {
		//const mainNav = document.querySelector('.c-main-navigation');
		const mobileLogin= document.querySelector('.c-header-mobile__login')
		const mobileLoginMenu = document.querySelector('.c-header-mobile .c-main-navigation__action-box__wrapper');
    window.addEventListener('click', function(e){
			if (mobileLogin && !mobileLogin.contains(e.target) && mobileLoginMenu && !mobileLoginMenu.contains(e.target)) mobileLoginMenu.classList.remove('c-main-navigation__action-box__wrapper--open');
      if (mainNav.classList.contains('c-main-navigation--active') && !mainNav.contains(e.target)) {
				hideContainer(document.querySelector('.c-main-navigation__trigger--expanded .c-main-navigation__button'));
      }
    });
  //}
}